// menuConfig.js
import { Inbox, Mail } from "@mui/icons-material";

const menuConfig = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <Inbox />,
    children: [],
  },
  {
    title: "Posts",
    path: "/posts",
    icon: <Inbox />,
    children: [],
  },
  {
    title: "Users",
    path: "/users",
    icon: <Inbox />,
    children: [],
  },
  {
    title: "Plans",
    path: "/plans",
    icon: <Inbox />,
    children: [],
  },
  {
    title: "Change Password",
    path: "/change-password",
    icon: <Inbox />,
    children: [],
  },
];

export default menuConfig;
