import { Button, Grid, TextField } from "@mui/material";
import React from "react";

export default function ChangePassword() {
  return (
    <>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        sx={{ pr: 2, m: 2, maxWidth: "100vw" }}
      >
        <Grid item>
          <h1>Change Password</h1>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        alignItems="flex-start"
        sx={{ pt: 2, pr: 2, maxWidth: "100vw" }}
        spacing={2}
      >
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Old Password"
            type="password"
            variant="outlined"
            sx={{ width: "600px" }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="New Password"
            type="password"
            variant="outlined"
            sx={{ width: "600px" }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Confirm New Password"
            type="password"
            variant="outlined"
            sx={{ width: "600px" }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button variant="contained" color="primary" sx={{ mt: 2 }}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
