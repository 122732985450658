import React from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Box,
  Modal,
} from "@mui/material";

import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "auto",
  height: "auto",
  transform: "translate(-50%, -50%)",
  p: 3,
  backgroundColor: "white",
};
export default function Users() {
  const data = [
    {
      srNo: 1,
      photo: "https://via.placeholder.com/300",
      username: "john_doe",
      likes: 150,
      views: 300,
      villages: "Springfield",
    },
    {
      srNo: 2,
      photo: "https://via.placeholder.com/150",
      username: "jane_smith",
      likes: 200,
      views: 450,
      villages: "Shelbyville",
    },
    {
      srNo: 3,
      photo: "https://via.placeholder.com/150",
      username: "michael_b",
      likes: 120,
      views: 250,
      villages: "Ogdenville",
    },
    {
      srNo: 4,
      photo: "https://via.placeholder.com/150",
      username: "lucy_l",
      likes: 300,
      views: 500,
      villages: "North Haverbrook",
    },
    {
      srNo: 5,
      photo: "https://via.placeholder.com/150",
      username: "mark_t",
      likes: 180,
      views: 350,
      villages: "Capital City",
    },
  ];

  const [image, setImage] = React.useState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        gap="15px"
        alignItems="center"
        sx={{ pt: 2, pr: 2, m: 2, maxWidth: "100vw" }}
      >
        <Grid item>
          <h1>Users</h1>
        </Grid>
        <Grid item>
          <TextField
            id="outlined-search"
            label="Search"
            type="search"
            sx={{
              width: "200px",
              mt: 2,
              mb: 2,
              "& .MuiInputBase-root": {
                height: "40px",
              },
              "& .MuiInputLabel-root": {
                lineHeight: "13px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Tooltip title="Filter">
            <Button variant="contained" sx={{ m: 2, mr: 0 }}>
              <FilterAltIcon />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        sx={{ maxWidth: "100vw", overflow: "auto" }}
      >
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ py: 2 }}>PROFILE</TableCell>
                <TableCell align="center" sx={{ py: 2 }}>
                  USERNAME
                </TableCell>
                <TableCell align="center" sx={{ py: 2 }}>
                  MOBILE NO
                </TableCell>
                <TableCell align="center" sx={{ py: 2 }}>
                  EMAIL
                </TableCell>
                <TableCell align="center" sx={{ py: 2 }}>
                  ADDRESS
                </TableCell>
                <TableCell align="center" sx={{ py: 2 }}>
                  SUBSCRIPTIONS
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow
                  onClick={() => {
                    console.log(row.photo);
                    setImage(row.photo);
                    handleOpen();
                  }}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    height: "60px",
                    cursor: "pointer",
                  }}
                >
                  <TableCell sx={{ py: 2 }}>
                    <Avatar src={row.photo}></Avatar>
                  </TableCell>
                  <TableCell align="center" sx={{ py: 2 }}>
                    {row.username}
                  </TableCell>
                  <TableCell align="center" sx={{ py: 2 }}>
                    {row.likes}
                  </TableCell>
                  <TableCell align="center" sx={{ py: 2 }}>
                    {row.views}
                  </TableCell>
                  <TableCell align="center" sx={{ py: 2 }}>
                    {row.villages}
                  </TableCell>
                  <TableCell align="center" sx={{ py: 2 }}>
                    Subscriptions
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TableContainer>
      {/* popup img modal */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 0,
            }}
          >
            <h2 style={{ margin: 0, marginBottom: "30px", padding: 0 }}>
              Post
            </h2>
            <CloseIcon onClick={handleClose} />
          </Box>
          <img src={image} alt="img" />
        </Box>
      </Modal>
    </>
  );
}
