import React, { useState } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Modal,
  MenuItem,
  Menu,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "auto",
  height: "auto",
  transform: "translate(-50%, -50%)",
  p: 3,
  backgroundColor: "white",
};

function Posts() {
  const data = [
    {
      srNo: 1,
      photo: "https://via.placeholder.com/300",
      username: "john_doe",
      likes: 150,
      views: 300,
      villages: "Springfield",
    },
    {
      srNo: 2,
      photo: "https://via.placeholder.com/150",
      username: "jane_smith",
      likes: 200,
      views: 450,
      villages: "Shelbyville",
    },
    {
      srNo: 3,
      photo: "https://via.placeholder.com/150",
      username: "michael_b",
      likes: 120,
      views: 250,
      villages: "Ogdenville",
    },
    {
      srNo: 4,
      photo: "https://via.placeholder.com/150",
      username: "lucy_l",
      likes: 300,
      views: 500,
      villages: "North Haverbrook",
    },
    {
      srNo: 5,
      photo: "https://via.placeholder.com/150",
      username: "mark_t",
      likes: 180,
      views: 350,
      villages: "Capital City",
    },
  ];

  const [image, setImage] = useState();
  const [open, setOpen] = useState(false);
  const [sortedData, setSortedData] = useState(data);
  const [sortOrder, setSortOrder] = useState({ column: "", order: "" });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const sortData = (column) => {
    const isAscending =
      sortOrder.column === column && sortOrder.order === "asc";
    const order = isAscending ? "desc" : "asc";
    const sorted = [...data].sort((a, b) => {
      if (a[column] < b[column]) return isAscending ? 1 : -1;
      if (a[column] > b[column]) return isAscending ? -1 : 1;
      return 0;
    });
    setSortedData(sorted);
    setSortOrder({ column, order });
  };

  const [state, setstate] = React.useState("");
  const [district, setdistrict] = React.useState("");
  const [taluka, settaluka] = React.useState("");
  const [village, setvillage] = React.useState("");
  const [like, setlike] = React.useState("");
  const [status, setstatus] = React.useState("");

  const handleChange = (event) => {
    setstate(event.target.value);
  };
  const handleChangeDis = (event) => {
    setdistrict(event.target.value);
  };
  const handleChangeTaluka = (event) => {
    settaluka(event.target.value);
  };
  const handleChangeVillage = (event) => {
    setvillage(event.target.value);
  };
  const handleChangeLike = (event) => {
    setlike(event.target.value);
  };
  const handleChangeStatus = (event) => {
    setstatus(event.target.value);
  };
  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        gap="15px"
        alignItems="center"
        sx={{ pt: 2, pr: 2, m: 2, maxWidth: "100vw" }}
      >
        <Grid item>
          <h1>Posts </h1>
        </Grid>

        <Grid item>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">State</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={state}
                label="Age"
                onChange={handleChange}
              >
                <MenuItem value={10}>Maharashtra</MenuItem>
                <MenuItem value={20}>Gujrat</MenuItem>
                <MenuItem value={30}>Goa</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">District</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={district}
                value={district}
                label="Age"
                onChange={handleChangeDis}
              >
                <MenuItem value={10}>Chh. Sambhajinagar</MenuItem>
                <MenuItem value={20}>Jalna</MenuItem>
                <MenuItem value={30}>Pune</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Taluka</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={taluka}
                label="Age"
                onChange={handleChangeTaluka}
              >
                <MenuItem value={10}>Khuldabad</MenuItem>
                <MenuItem value={20}>Sillod</MenuItem>
                <MenuItem value={30}>Paithan</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">village</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={village}
                label="Age"
                onChange={handleChangeVillage}
              >
                <MenuItem value={10}>Ladsawangi</MenuItem>
                <MenuItem value={20}>Paithan</MenuItem>
                <MenuItem value={30}>Karmad</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Like</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={like}
                label="Age"
                onChange={handleChangeLike}
              >
                <MenuItem value={10}>Most Liked</MenuItem>
                <MenuItem value={20}>Most Disliked</MenuItem>
                <MenuItem value={30}>Most View</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                label="Age"
                onChange={handleChangeStatus}
              >
                <MenuItem value={10}>Block</MenuItem>
                <MenuItem value={20}>Unblock</MenuItem>
                <MenuItem value={30}>Blank</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item>
          <TextField
            id="outlined-search"
            label="Search"
            type="search"
            sx={{
              mt: 2,
              mb: 2,
              width: "220px",
              "& .MuiInputBase-root": {
                height: "40px",
              },
              "& .MuiInputLabel-root": {
                lineHeight: "13px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button variant="contained" sx={{ m: 2, mr: 0 }}>
            <FilterAltIcon />
          </Button>
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        sx={{ maxWidth: "100vw", overflow: "auto" }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ py: 2 }}>
                Sr. No
              </TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                POST
              </TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                USERNAME
              </TableCell>
              <TableCell align="center">
                LIKES
                {/* Conditionally render the arrow based on the sortOrder state */}
                {sortOrder.column === "likes" && sortOrder.order === "asc" ? (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ visibility: "visible", cursor: "pointer" }}
                    onClick={() => sortData("likes")}
                  />
                ) : sortOrder.column === "likes" &&
                  sortOrder.order === "desc" ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ visibility: "visible", cursor: "pointer" }}
                    onClick={() => sortData("likes")}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ visibility: "visible", cursor: "pointer" }}
                    onClick={() => sortData("likes")}
                  />
                )}
              </TableCell>

              <TableCell align="center">
                VIEWS
                {/* Conditionally render the arrow based on the sortOrder state */}
                {sortOrder.column === "views" && sortOrder.order === "asc" ? (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ visibility: "visible", cursor: "pointer" }}
                    onClick={() => sortData("views")}
                  />
                ) : sortOrder.column === "views" &&
                  sortOrder.order === "desc" ? (
                  <ArrowDownwardIcon
                    fontSize="small"
                    sx={{ visibility: "visible", cursor: "pointer" }}
                    onClick={() => sortData("views")}
                  />
                ) : (
                  <ArrowUpwardIcon
                    fontSize="small"
                    sx={{ visibility: "visible", cursor: "pointer" }}
                    onClick={() => sortData("views")}
                  />
                )}
              </TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                VILLAGES
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row) => (
              <TableRow
                key={row.srNo}
                onClick={() => {
                  setImage(row.photo);
                  handleOpen();
                }}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  height: "60px",
                  cursor: "pointer",
                }}
              >
                <TableCell
                  align="center"
                  component="th"
                  scope="row"
                  sx={{ py: 1 }}
                >
                  {row.srNo}
                </TableCell>
                <TableCell align="center" sx={{ py: 2 }}>
                  <img style={{ width: "50px" }} src={row.photo} alt="" />
                </TableCell>
                <TableCell align="center" sx={{ py: 2 }}>
                  {row.username}
                </TableCell>
                <TableCell align="center" sx={{ py: 2 }}>
                  {row.likes}
                </TableCell>
                <TableCell align="center" sx={{ py: 2 }}>
                  {row.views}
                </TableCell>
                <TableCell align="center" sx={{ py: 2 }}>
                  {row.villages}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* popup img modal */}
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 0,
            }}
          >
            <h2 style={{ margin: 0, marginBottom: "30px", padding: 0 }}>
              Post
            </h2>
            <CloseIcon onClick={handleClose} />
          </Box>
          <img src={image} alt="img" />
        </Box>
      </Modal>
    </>
  );
}

export default Posts;
