import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./screens/Login";
import Dashboard from "./screens/Dashboard";
import Template from "./layouts/Template";
import Posts from "./screens/Posts/Posts";
import Users from "./screens/Users/Users";
import { Plans } from "./screens/Plans/Plans";
import { AddPlan } from "./screens/Plans/AddPlan";
import ChangePassword from "./screens/ChangePassword";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard"
          element={<Template mainPage={<Dashboard />} />}
        />
        <Route path="/posts" element={<Template mainPage={<Posts />} />} />
        <Route path="/users" element={<Template mainPage={<Users />} />} />
        <Route path="/plans" element={<Template mainPage={<Plans />} />} />
        <Route
          path="/plans/add"
          element={<Template mainPage={<AddPlan />} />}
        />
        <Route
          path="/change-password"
          element={<Template mainPage={<ChangePassword />} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
