import { domain } from "./domain";

const apiGeneral = {
  login: `${domain}/api/v1/admin/login`,
};

//api which required authentication
const apiAuth = {
  getPlans: `${domain}/api/v1/admin/plan/get`,
  deletePlan: `${domain}/api/v1/admin/plan/delete`,
  createPlan: `${domain}/api/v1/admin/plan/create`,
};

export { apiAuth, apiGeneral };
