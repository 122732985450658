import axios from "axios";
import Swal from "sweetalert2";

async function networkRequest(
  url,
  handleLoading,
  handleResponse,
  requestType = "get",
  data = null
) {
  // Set headers
  const headers = {
    Authorization: localStorage.getItem("access_token"),
  };

  // Set loading state to true before making the request
  handleLoading(true);

  try {
    let response;

    if (requestType.toLowerCase() === "post") {
      headers["Content-Type"] = "multipart/form-data";
      const formData = new FormData();

      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          formData.append(key, data[key]);
        }
      }

      console.log("Making POST request to:", url, "with data:", formData);
      response = await axios.post(url, formData, { headers });
    } else if (requestType.toLowerCase() === "get") {
      const params = new URLSearchParams(data).toString();
      console.log("Making GET request to:", `${url}?${params}`);
      response = await axios.get(`${url}?${params}`, { headers });
    } else {
      throw new Error(
        "Unsupported request type. Only GET and POST are allowed."
      );
    }

    // Handle the response
    if (response?.data?.success) {
      handleResponse(response.data);
    } else {
      console.log("API Error Response:", response.data);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: response.data?.message || "Something went wrong!",
      });
    }
  } catch (error) {
    console.log("Request Error:", error);

    if (axios.isAxiosError(error) && error.response) {
      const { status } = error.response;

      if (status === 401) {
        localStorage.clear();
        return (window.location.href = "/");
      }

      let errorMessage = error.response.data["message"];
      if (Object.keys(errorMessage).length !== 0) {
        errorMessage = JSON.stringify(errorMessage);
      }

      Swal.fire({
        title: "Oops",
        text: errorMessage,
        icon: "error",
      });
    }
  } finally {
    handleLoading(false);
  }
}

export { networkRequest };
